import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { theme, Card, Layout, Button } from 'antd';
import * as XLSX from 'xlsx';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';
import { AppDispatch, RootState } from 'store/configureStore';
import TitleBar from 'components/TitleBar';
import { useLocation } from 'react-router-dom';
import CustomSelect from 'components/CustomSelect';
import { TitleList } from 'store/ducks/globalTypes';
import qs from 'qs';
import GoBackButton from 'components/GoBackButton';
import ReportModal from 'components/Modal/Modal';
import { tableData } from 'constants/excelData';
import * as Styled from '../Layout.styled';
import { globalOp } from 'store/ducks/Global';

const { useToken } = theme;
const { Sider } = Layout;
const FiltersParam = withDefault(ArrayParam, []);

const ReportingLayout: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { token } = useToken();
  const { scheme } = useSelector((state: RootState) => state.global);
  const [open, setOpen] = useState(false);

  const { filtersData } = useSelector((state: RootState) => ({
    filtersData: state.global.filtersData as unknown as FilterOption[],
  }));

  interface FilterOption {
    filter: string;
    options: {
      id: number;
      name_of_entity: string;
    }[];
  }

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [query, setQuery] = useQueryParams({
    entity: FiltersParam,
  });

  useEffect(() => {
    dispatch(globalOp.setFilters());
  }, [dispatch]);

  const handleFilterChange = (value: string[]) => {
    setQuery((latestQuery: any) => ({
      ...latestQuery,
      entity_name: value,
    }));
  };

  const entityName =
  typeof queryParams.entity_name === 'string'
    ? queryParams.entity_name
    : '';


  const extractedOptions = filtersData.flatMap((item) => {
    if (Array.isArray(item.options)) {
      return item.options.map((option) => ({
        label: option.name_of_entity,
        value: option.id,
      }));
    } else if (item.options) {
      const { name_of_entity } = item.options;
      return [{ label: name_of_entity, value: name_of_entity }];
    } else {
      return [];
    }
  });

  const applyFilters = () => {
    setOpen(false);
  };

  const handleExcelSheetsDownload = async () => {
    try {
      const params = {};
      const fetchOperations = [
        globalOp.fetchEntityContractualArrangements(params),
        globalOp.fetchBranches(params),
        globalOp.fetchContractualArrangementsLei(params),
        globalOp.fetchContractualArrangementsICT(params),
        globalOp.fetchContractualArrangementsICT(params),
      ];
  
      const sheetTitles = ['RT.02.03', 'RT.01.03', 'RT.03.01', 'RT.03.03', 'RT.04.01'];
  
      const results = await Promise.all(fetchOperations);
      const wb = XLSX.utils.book_new();
  
      results.forEach((data, index) => {
        const sheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, sheet, sheetTitles[index]);
      });
  
      XLSX.writeFile(wb, `Registry of Information for ${entityName}.xlsx`);
  
      setOpen(false);
    } catch (error) {
      console.error('Error fetching data or generating Excel:', error);
    }
  };
  
  return (
    <Styled.StyledLayout token={token}>
      <TitleBar title={TitleList.REPORTING} />
      <h4>Informationsregister</h4>
      <Styled.StyledHeader token={token}>
        <Button
          type="primary"
          onClick={showDrawer}
          icon={open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        ></Button>
        <GoBackButton />
      </Styled.StyledHeader>
      <Styled.HorizontalLayout theme={scheme}>
        <Styled.Container>
       
          <Styled.GridContainer>
            <Styled.ReportCard>
            <Styled.ExcelDownload>
            <Card.Meta
                  title={
                    <Styled.StyledButton type="primary"  onClick={handleExcelSheetsDownload}> Registry of Information </Styled.StyledButton>
                  }
                  description={<p>Format to be sent to the regulator</p>}
                />
            </Styled.ExcelDownload>
            </Styled.ReportCard>
            {tableData.map((item, index) => (
              <Styled.ReportCard key={index}>
                <Card.Meta
                  title={
                    <ReportModal title={item.title} tableNumber={item.title} />
                  }
                  description={<p>{item.description}</p>}
                />
              </Styled.ReportCard>
            ))}
          </Styled.GridContainer>
        </Styled.Container>
      </Styled.HorizontalLayout>
      <Styled.StyledLayout token={token}>
        <Sider>
          <Styled.AntDrawer title="Filters" onClose={onClose} open={open}>
            <CustomSelect
              placeholder="Select Options"
              onChange={handleFilterChange}
              options={extractedOptions}
              defaultValue={queryParams.entity}
            />
            <Styled.StyledButton type="primary" onClick={applyFilters}>
              Display
            </Styled.StyledButton>
          </Styled.AntDrawer>
        </Sider>
      </Styled.StyledLayout>
    </Styled.StyledLayout>
  );
};

export default ReportingLayout;
