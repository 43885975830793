export const variablesList = {
  mainTheme: {
    defaultTabBg: '#34899c',
    tabSelected: 'rgba(52, 137, 156, 0.7)',
    iconButtonBg: 'rgba(0, 54, 84, 0.5)',
    iconButtonBgHover: 'rgba(0, 54, 84, 0.3)',
    menuBackground: '#003654',
    menuBorder: '1px solid #4ed4ff',
    buttonPrimaryBgHover: '#82adad',
    menuColor: '#b4e1ed',
    menuColorHover: '#b4e1ed',
    menuItemBg: '#7cdcfc',
    colorBorder: '2px solid #5F9EA0',
    checkboxBorder: '2px solid #fff',
    colorTypography: '#000',
    tableCellColor: '#fff',
    datePickerDayColor: '#5f9ea0',
    tableBorder: '#fff',
    bgColor: '#e1e4f0',
    nestedTableBorder: '1px solid white',
  },
  firstTheme: {
    defaultTabBg: 'rgb(68, 60, 104)',
    tabSelected: 'rgba(68, 60, 104, 0.7)',
    iconButtonBg: 'rgba(24, 18, 43, 0.5)',
    iconButtonBgHover: 'rgba(24, 18, 43, 0.3)',
    menuBackground: 'rgba(24, 18, 43, 0.9)',
    menuBorder: '1px solid rgba(24, 18, 43, 0.2)',
    buttonPrimaryBgHover: 'rgb(99, 89, 133, 0.8)',
    menuColor: '#fff',
    menuColorHover: '#fff',
    menuItemBg: '#efecf0',
    colorBorder: '2px solid #443C68',
    checkboxBorder: '2px solid #fff',
    colorTypography: '#000',
    tableCellColor: '#fff',
    datePickerDayColor: '#443C68',
    tableBorder: '#fff',
    bgColor: '#efecf0',
    nestedTableBorder: '1px solid white',
  },
  secondTheme: {
    defaultTabBg: 'rgb(73, 85, 121)',
    tabSelected: 'rgba(73, 85, 121, 0.7)',
    iconButtonBg: 'rgba(38, 49, 89, 0.6)',
    iconButtonBgHover: 'rgba(38, 49, 89, 0.6)',
    menuBackground: '#251749',
    menuBorder: '1px solid #251749',
    buttonPrimaryBgHover: 'rgba(73, 85, 121, 0.8)',
    menuColor: '#fff',
    menuColorHover: '#fff',
    menuItemBg: 'rgba(255, 251, 235, 0.5)',
    colorBorder: '2px solid #495579',
    checkboxBorder: '2px solid #495579',
    colorTypography: '#000',
    tableCellColor: '#fff',
    datePickerDayColor: '#495579',
    tableBorder: 'rgb(38, 49, 89)',
    bgColor: 'rgb(255, 251, 235)',
    nestedTableBorder: '1px solid white',
  },
  thirdTheme: {
    // defaultTabBg: "#678983",
    defaultTabBg: '#5a6f94',

    // tabSelected: "rgba(103, 137, 131, 0.7)",
    tabSelected: 'rgba(172, 196, 221, 0.7)',

    // iconButtonBg: "#E6DDC4",
    iconButtonBg: '#5a6f94',

    // iconButtonBgHover: "rgba(230, 221, 196, 0.5)",
    iconButtonBgHover: 'rgba(172, 196, 221, 0.5)',

    // menuBackground: "rgba(103, 137, 131, 0.7)",
    menuBackground: 'rgba(172, 196, 221, 0.7)',

    // menuBorder: "1px solid #678983",
    menuBorder: '1px solid #5a6f94',

    // buttonPrimaryBgHover: "rgba(103, 137, 131, 0.7)",
    buttonPrimaryBgHover: 'rgba(172, 196, 221, 0.7)',

    // menuColor: "rgba(240, 233, 210, 0.7)",
    menuColor: 'rgba(172, 196, 221, 0.7)',

    menuColorHover: '#fff',
    // menuItemBg: "rgba(240, 233, 210, 0.5)",
    menuItemBg: 'rgba(172, 196, 221, 0.5)',

    // colorBorder: "2px solid #678983",
    colorBorder: '2px solid #5a6f94',

    // checkboxBorder: "2px solid rgb(103, 137, 131)",
    checkboxBorder: '2px solid rgba(172, 196, 221)',

    // colorTypography: "rgba(230, 221, 196, 0.8)",
    colorTypography: '#5a6f94',

    tableCellColor: '#000',
    // datePickerDayColor: "#678983",
    datePickerDayColor: '#5a6f94',

    tableBorder: '#fff',
    bgColor: '#181D31',
    nestedTableBorder: '1px solid white',
  },
  fourthTheme: {
    defaultTabBg: 'rgba(73, 113, 116, 0.8)',
    tabSelected: '#497174',
    iconButtonBg: 'rgba(73, 113, 116, 0.6)',
    iconButtonBgHover: 'rgba(73, 113, 116, 0.8)',
    menuBackground: '#497174',
    menuBorder: '1px solid #497174',
    buttonPrimaryBgHover: 'rgba(235, 100, 64, 0.8)',
    menuColor: '#D6E4E5',
    menuColorHover: '#fff',
    menuItemBg: 'rgba(214, 228, 229, 0.6)',
    colorBorder: '2px solid rgb(95, 158, 160)',
    checkboxBorder: '2px solid rgba(235, 100, 64, 0.8)',
    colorTypography: '#000',
    tableCellColor: '#fff',
    datePickerDayColor: 'rgb(95, 158, 160)',
    tableBorder: '#497174',
    bgColor: '#EFF5F5',
    nestedTableBorder: '1px solid white',
  },
  fifthTheme: {
    // defaultTabBg: "rgba(55, 146, 55, 0.6)",
    defaultTabBg: 'rgba(180, 99, 135, 0.6)',
    // tabSelected: "rgba(55, 146, 55, 0.9)",
    tabSelected: 'rgba(180, 99, 135, 0.9)',
    // iconButtonBg: "rgba(55, 146, 55, 0.6)",
    iconButtonBg: 'rgba(180, 99, 135, 0.9)',
    // iconButtonBgHover: "rgba(55, 146, 55, 0.8)",
    iconButtonBgHover: 'rgba(180, 99, 135, 0.8)',
    menuBackground: '#379237',
    menuBorder: '1px solid #379237',
    buttonPrimaryBgHover: 'rgba(209, 77, 114, 0.8)',
    menuColor: '#dbeff6',
    menuColorHover: '#fff',
    menuItemBg: 'rgba(219, 239, 246, 0.7)',
    colorBorder: '2px solid #379237',
    checkboxBorder: '2px solid #D14D72',
    colorTypography: '#000',
    tableCellColor: '#fff',
    datePickerDayColor: '#379237',
    // tableBorder: "rgba(55, 146, 55, 0.9)",
    tableBorder: 'rgba(180, 99, 135, 0.9))',
    bgColor: '#cceefe',
    nestedTableBorder: '1px solid white',
  },
  sixthTheme: {
    // defaultTabBg: "rgba(52, 92, 164, 0.5)",
    // tabSelected: "#254276",
    // iconButtonBg: "#254276",
    // iconButtonBgHover: "#254276",
    // menuBackground: "#254276",
    // menuBorder: "1px solid rgb(55,91,161)",
    // buttonPrimaryBgHover: "rgba(243,174,65, 0.8)",
    // menuColor: "#dbeff6",
    // menuColorHover: "#fff",
    // menuItemBg: "rgba(219, 239, 246, 0.7)",
    // colorBorder: "2px solid #6cf8ff",
    // checkboxBorder: "2px solid rgb(243,174,65)",
    // colorTypography: "rgb(243,174,65)",
    // tableCellColor: "#fff",
    // datePickerDayColor: "#254276",
    // tableBorder: "#254276",
    // bgColor: "#1c5454",
    // nestedTableBorder: "1px solid rgb(243,174,65)",

    defaultTabBg: 'rgba(52, 92, 164, 0.5)',
    tabSelected: '#6e5c4e',
    iconButtonBg: '#6e5c4e',
    iconButtonBgHover: '#6e5c4e',
    menuBackground: '#6e5c4e',
    menuBorder: '1px solid rgb(55,91,161)',
    buttonPrimaryBgHover: 'rgba(243,174,65, 0.8)',
    menuColor: '#dbeff6',
    menuColorHover: '#fff',
    menuItemBg: 'rgba(219, 239, 246, 0.7)',
    colorBorder: '2px solid #6cf8ff',
    checkboxBorder: '2px solid rgb(243,174,65)',
    colorTypography: '#6e5c4e',
    tableCellColor: '#fff',
    datePickerDayColor: '#6e5c4e',
    tableBorder: '#6e5c4e',
    bgColor: '#1c5454',
    nestedTableBorder: '1px solid rgb(243,174,65)',
  },
  seventhTheme: {
    defaultTabBg: 'rgba(157, 178, 191, 0.7)',
    tabSelected: '#9DB2BF',
    iconButtonBg: '#9DB2BF',
    iconButtonBgHover: '#9DB2BF',
    menuBackground: '#9DB2BF',
    menuBorder: '1px solid #9DB2BF',
    buttonPrimaryBgHover: 'rgba(115, 162, 193, 0.8)',
    menuColor: '#dbeff6',
    menuColorHover: '#fff',
    menuItemBg: 'rgba(219, 239, 246, 0.7)',
    colorBorder: '2px solid #73a2c1',
    checkboxBorder: '2px solid #73a2c1',
    colorTypography: '#73a2c1',
    tableCellColor: '#fff',
    datePickerDayColor: '#9DB2BF',
    tableBorder: '#9DB2BF',
    bgColor: '#fff',
    nestedTableBorder: '1px solid #9DB2BF',
  },
  eighthTheme: {
    defaultTabBg: '#333e4e',
    tabSelected: '#28313e',
    iconButtonBg: '#28313e',
    iconButtonBgHover: '#28313e',
    menuBackground: '#28313e',
    menuBorder: '1px solid #28313e',
    buttonPrimaryBgHover: '#2ea043',
    menuColor: '#dbeff6',
    menuColorHover: '#fff',
    menuItemBg: 'rgba(219, 239, 246, 0.7)',
    colorBorder: '2px solid #238636',
    checkboxBorder: '2px solid #238636',
    colorTypography: 'rgba(38,136,60,0.7)',
    tableCellColor: '#fff',
    datePickerDayColor: '#238636',
    tableBorder: '#238636',
    bgColor: '#010405',
    nestedTableBorder: '1px solid #238636',
  },
};
