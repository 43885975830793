export const themesList = {
  mainTheme: {
    colorPrimary: '#5f9ea0',
    mainColor: '#fff',
    colorBgLayout: '#e1e4f0',
    colorPrimaryBgHover: 'rgba(52, 137, 156, 0.7)',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: '#778fa2',
    inputBorderColor: '#00fff0',
    inputColorPrimary: '#5f9ea0',
    colorFillContent: 'rgba(0, 54, 84, 0.5)',
    colorFillContentHover: 'rgba(0, 54, 84, 0.3)',
    colorFillQuaternary: 'rgba(0, 54, 84, 0.7)',
    shadow:
      '-90px 0 60px -30px #003654 inset, 90px 0px 60px -30px #003654 inset',
  },
  firstTheme: {
    colorPrimary: '#635985',
    mainColor: '#fff',
    colorBgLayout: '#efecf0',
    colorPrimaryBgHover: '#635985',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: '#847f8e',
    inputBorderColor: 'rgba(24, 18, 43, 0.9)',
    inputColorPrimary: '#847f8e',
    colorFillContent: '#847f8e',
    colorFillContentHover: 'rgba(24, 18, 43, 0.3)',
    colorFillQuaternary: 'rgba(24, 18, 43, 0.7)',
    shadow:
      '-90px 0 60px -30px rgba(24, 18, 43, 0.9) inset, 90px 0px 60px -30px rgba(24, 18, 43, 0.9) inset',
  },
  secondTheme: {
    colorPrimary: '#495579',
    mainColor: '#fff',
    colorBgLayout: 'rgb(255, 251, 235)',
    colorPrimaryBgHover: 'rgba(73, 85, 121, 0.7)',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: '#495579',
    inputBorderColor: '#263159',
    inputColorPrimary: '#495579',
    colorFillContent: 'rgba(38, 49, 89, 0.6)',
    colorFillContentHover: '#263159',
    colorFillQuaternary: '#263159',
    shadow:
      '-90px 0 60px -30px #251749 inset, 90px 0px 60px -30px #251749 inset',
  },
  thirdTheme: {
    // colorPrimary: "#678983",
    colorPrimary: '#5a6f94',
    mainColor: '#fff',
    // colorBgLayout: "#181D31",
    colorBgLayout: 'rgba(172, 196, 221, 0.6)',
    // colorPrimaryBgHover: "rgba(103, 137, 131, 0.7)",
    colorPrimaryBgHover: 'rgba(172, 196, 221, 0.7)',
    colorBorderSecondary: '#fff',
    // colorBgSpotlight: "#E6DDC4",
    colorBgSpotlight: '#5a6f94',
    // inputBorderColor: "#E6DDC4",
    inputBorderColor: '#788baf',
    // inputColorPrimary: "#678983",
    inputColorPrimary: '#788baf',
    // colorFillContent: "#E6DDC4",
    colorFillContent: '#5a6f94',
    // colorFillContentHover: "rgba(230, 221, 196, 0.8)",
    colorFillContentHover: 'rgba(172, 196, 221, 0.8)',
    // colorFillQuaternary: "rgba(230, 221, 196, 0.8)",
    colorFillQuaternary: '#5a6f94',
    shadow:
      // "-90px 0 60px -30px #678983 inset, 90px 0px 60px -30px #678983 inset",
      '-90px 0 60px -30px #5a6f94 inset, 90px 0px 60px -30px #5a6f94 inset',
  },
  fourthTheme: {
    colorPrimary: '#EB6440',
    mainColor: '#fff',
    colorBgLayout: '#EFF5F5',
    colorPrimaryBgHover: '#497174',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: 'rgba(73, 113, 116, 0.6)',
    inputBorderColor: 'rgba(73, 113, 116, 0.8)',
    inputColorPrimary: 'rgba(73, 113, 116, 0.8)',
    colorFillContent: 'rgba(73, 113, 116, 0.6)',
    colorFillContentHover: '#497174',
    colorFillQuaternary: '#497174',
    shadow:
      '-90px 0 60px -30px #497174 inset, 90px 0px 60px -30px #497174 inset',
  },
  fifthTheme: {
    // colorPrimary: "#D14D72",
    colorPrimary: '#b46387',
    mainColor: '#fff',
    // colorBgLayout: "#cceefe",
    colorBgLayout: '#efcbc6',
    // colorPrimaryBgHover: "rgba(55, 146, 55, 0.9)",
    colorPrimaryBgHover: 'rgba(180, 99, 135, 0.9)',
    colorBorderSecondary: '#aeaeae',
    // colorBgSpotlight: "rgba(55, 146, 55, 0.6)",
    colorBgSpotlight: 'rgba(180, 99, 135, 0.6)',
    // inputBorderColor: "rgba(55, 146, 55, 0.9)",
    inputBorderColor: '#773035',
    // inputColorPrimary: "rgba(55, 146, 55, 0.6)",
    inputColorPrimary: '#773035',
    // colorFillContent: "rgba(55, 146, 55, 0.6)",
    colorFillContent: 'rgba( 180, 99, 135, 0.6)',
    // colorFillContentHover: "rgba(55, 146, 55, 0.8)",
    colorFillContentHover: 'rgba(180, 99, 135, 0.6)',
    // colorFillQuaternary: "rgba(55, 146, 55, 0.9)",
    colorFillQuaternary: 'rgba(180, 99, 135, 0.9)',
    shadow:
      // "-90px 0 60px -30px #379237 inset, 90px 0px 60px -30px #379237 inset",
      '-90px 0 60px -30px #b46387 inset, 90px 0px 60px -30px #b46387 inset',
  },
  sixthTheme: {
    // colorPrimary: "rgb(243,174,65)",
    // mainColor: "#fff",
    // colorBgLayout: "#1c5454",
    // colorPrimaryBgHover: "#254276",
    // colorBorderSecondary: "#aeaeae",
    // colorBgSpotlight: "#254276",
    // inputBorderColor: "#254276",
    // inputColorPrimary: "#254276",
    // colorFillContent: "#254276",
    // colorFillContentHover: "#254276",
    // colorFillQuaternary: "#254276",
    // shadow:
    //   "-90px 0 60px -30px #254276 inset, 90px 0px 60px -30px #254276 inset",
    colorPrimary: 'rgb(243,174,65)',
    mainColor: '#fff',
    colorBgLayout: 'rgb(225, 215, 204)',
    colorPrimaryBgHover: '#6e5c4e',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: '#6e5c4e',
    inputBorderColor: '#6e5c4e',
    inputColorPrimary: '#6e5c4e',
    colorFillContent: '#6e5c4e',
    colorFillContentHover: '#6e5c4e',
    colorFillQuaternary: '#6e5c4e',
    shadow:
      '-90px 0 60px -30px #6e5c4e inset, 90px 0px 60px -30px #6e5c4e inset',
  },
  seventhTheme: {
    colorPrimary: '#73a2c1',
    mainColor: '#fff',
    colorBgLayout: '#fff',
    colorPrimaryBgHover: '#9DB2BF',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: '#9DB2BF',
    inputBorderColor: '#9DB2BF',
    inputColorPrimary: '#9DB2BF',
    colorFillContent: '#9DB2BF',
    colorFillContentHover: '#9DB2BF',
    colorFillQuaternary: '#9DB2BF',
    shadow:
      '-90px 0 60px -30px #9DB2BF inset, 90px 0px 60px -30px #9DB2BF inset',
  },
  eighthTheme: {
    colorPrimary: '#238636',
    mainColor: '#fff',
    colorBgLayout: '#010405',
    colorPrimaryBgHover: '#28313e',
    colorBorderSecondary: '#aeaeae',
    colorBgSpotlight: '#28313e',
    inputBorderColor: '#28313e',
    inputColorPrimary: '#28313e',
    colorFillContent: '#28313e',
    colorFillContentHover: '#28313e',
    colorFillQuaternary: '#28313e',
    shadow:
      '-90px 0 60px -30px #28313e inset, 90px 0px 60px -30px #28313e inset',
  },
};
