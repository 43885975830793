import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { generateExcelData, tableData } from '../../constants/excelData';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import RT0203 from 'components/Tables/Reporting/RT0203/RT0203';
import RT0103 from 'components/Tables/Reporting/RT0103/RT0103';
import RT0301 from 'components/Tables/Reporting/RT0301/RT0301';
import RT0303 from 'components/Tables/Reporting/RT0303/RT0303';
import RT0401 from 'components/Tables/Reporting/RT0401/RT0401';
import { globalOp } from 'store/ducks/Global';

export interface ReportModalProps {
  title: string;
  tableNumber: string;
}

const ReportModal: React.FC<ReportModalProps> = ({ title, tableNumber }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const entityName =
    typeof queryParams.entity_name === 'string'
      ? queryParams.entity_name
      : '';

  const showModal = () => {
    setOpen(true);
  };

  const fetchDataByTableNumber = async (tableNumber: string, params: any) => {
    switch (tableNumber) {
      case 'RT.01.03':
        return await globalOp.fetchBranches(params);
      case 'RT.02.03':
        return await globalOp.fetchEntityContractualArrangements(params);
      case 'RT.03.01':
        return await globalOp.fetchContractualArrangementsLei(params);
      case 'RT.03.03':
        return await globalOp.fetchContractualArrangementsICT(params);
      case 'RT.04.01':
        return await globalOp.fetchContractualArrangementsICT(params);
      default:
        throw new Error('Invalid table number');
    }
  };

  const handleOk = async () => {
    setLoading(true);
    
    try {
      const params = {
        entity_name: queryParams.entity_name,
      };
  
      const fetchedData = await fetchDataByTableNumber(tableNumber, params);
      
      const { wb, title: excelTitle } = generateExcelData(fetchedData, title);
      console.log(fetchedData, 'fetchedData');
      XLSX.writeFile(wb, `${excelTitle}.xlsx`);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };
  
  

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {title}
      </Button>
      <Modal
        open={open}
        centered
        width={1500}
        title={title}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          entityName &&(
            <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            <DownloadOutlined />
              Download
           </Button>
          )
        ]}
      >
        {title === 'RT.02.03' ? (
          <RT0203 entityName={entityName} />
        ) : title === 'RT.01.03' ? (
          <RT0103 entityName={entityName}/>
        ) : title === 'RT.03.01' ? (
          <RT0301 entityName={entityName}/>
        ) : title === 'RT.03.03' ? (
          <RT0303 entityName={entityName}/>
        ) : title === 'RT.04.01' ? (
          <RT0401 entityName={entityName}/> )
        : null }
      </Modal>
    </>
  );
};

export default ReportModal;
