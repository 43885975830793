import { AliasToken } from 'antd/es/theme/interface';

export interface IToken {
  token: AliasToken;
}

export interface IFilters {
  filter: string;
  options: ISelectionOption[];
}

export interface ISelectionOption {
  value: string;
  label: string;
}

export interface IResponseFilters {
  ent?: number;
}

export enum ThemeList {
  MAIN_THEME = 'mainTheme',
  FIRST_THEME = 'firstTheme',
  SECOND_THEME = 'secondTheme',
  THIRD_THEME = 'thirdTheme',
  FOURTH_THEME = 'fourthTheme',
  FIFTH_THEME = 'fifthTheme',
  SIXTH_THEME = 'sixthTheme',
  SEVENTH_THEME = 'seventhTheme',
  EIGHTH_THEME = 'eighthTheme',
}

export interface ICustomMenuItem {
  label: JSX.Element;
  key: string;
  path: string;
}

export enum TitleList {
  WORKING_AREAS = 'Working areas',
  INFO_REG = 'Progriscom',
  DATA_ENTRY = 'Progriscom - Dateneingabe',
  REPORTING = 'Progriscom - Reporting',
}
