import AxiosInstance from './AxiosInterceptor';
import Helpers from './Helpers';

const ApiService = {
  getFilters: () =>
    AxiosInstance({
      url: '/api/entities',
      method: 'get',
    }),

  getEntityContractualArrangements: (data: any) => {
    const filtersParams = Helpers.getFilterParams();
    return AxiosInstance({
      url: '/api/entity-contractual-arrangements',
      method: 'get',
      params: {
        entity_name: filtersParams.entity_name?.[0],
      },
    });
  },

  postEntity: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-entity',
      method: 'post',
      data: entityData,
    }),

  getBranches: (data: any) => {
    const filtersParams = Helpers.getFilterParams();
    return AxiosInstance({
      url: '/api/branches',
      method: 'get',
      params: {
        entity_name: filtersParams.entity_name?.[0],
      },
    });
  },

  getContractualArrangementsLei: (data: any) => {
    const filtersParams = Helpers.getFilterParams();
    return AxiosInstance({
      url: '/api/contractual-arrangements-lei',
      method: 'get',
      params: {
        entity_name: filtersParams.entity_name?.[0],
      },
    });
  },
  getContractualArrangementsICT: (data: any) => {
    const filtersParams = Helpers.getFilterParams();
    return AxiosInstance({
      url: '/api/contractual-arrangements-ict',
      method: 'get',
      params: {
        entity_name: filtersParams.entity_name?.[0],
      },
    });
  },

  getContractualArrangementsICTUse: (data: any) => {
    const filtersParams = Helpers.getFilterParams();
    return AxiosInstance({
      url: '/api/contractual-arrangements-ict-use',
      method: 'get',
      params: {
        entity_name: filtersParams.entity_name?.[0],
      },
    });
  },

  postEntityType: (entityData: any) =>
    AxiosInstance({
      url: '/api/insert-entity-type',
      method: 'post', 
      data: entityData,
    }),

    postHierarchyGroup: (hierarchyGroupData: any) =>
      AxiosInstance({
        url: '/api/insert-hierarchy-group',
        method: 'post',
        data: hierarchyGroupData,
      }),

      getAllEntities: () => {
        return AxiosInstance({
          url: '/api/get-all-entities',
          method: 'get',
        });
      },

};

export default ApiService;
