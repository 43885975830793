import { Dispatch } from '@reduxjs/toolkit';
import globalSlice from './globalSlice';
import { notification } from 'antd';
import { IFilters, ThemeList } from '../globalTypes';
import { ITableFilters, IRegulatoryReq, IEntitiesResponse } from './types';
import ApiService from 'utils/ApiCalls';
import Helpers from 'utils/Helpers';

const {
  set_theme,
  set_search_params,
  set_loading,
  set_applied_filters_result,
  set_table_filters,
  set_filters_data,
  set_entities,
} = globalSlice.actions;

const setTheme = (theme: ThemeList) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_theme(theme));
  } catch (error) {
    console.error(error);
  }
};

const setSearchParams = (params: string) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_search_params(params));
  } catch (error) {
    console.error(error);
  }
};

const setAppliedFilersResult =
  (data: IRegulatoryReq[]) => (dispatch: Dispatch) => {
    try {
      dispatch(set_loading());
      dispatch(set_applied_filters_result(data));
    } catch (error) {
      console.error(error);
    }
  };

const setTableFilter = (filter: ITableFilters) => (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    dispatch(set_table_filters(filter));
  } catch (error) {
    console.error(error);
  }
};

const setFilters = () => async (dispatch: Dispatch) => {
  try {
    dispatch(set_loading());
    const response = await ApiService.getFilters();
    let fetchedFilters: IFilters[] = [];

    if (response.data && !Helpers.isObjEmpty(response.data)) {
      fetchedFilters = Object.keys(response.data).map((key) => {
        let filterName, filterOptions;

        filterName = key.charAt(0).toUpperCase() + key.slice(1);
        filterOptions = response.data[key];

        return {
          filter: filterName,
          options: filterOptions,
        };
      });
    }

    dispatch(set_filters_data(fetchedFilters));
  } catch (error) {
    console.error(error);
  }
};

const fetchEntityContractualArrangements = async (data: any) => {
  try {
    const response = await ApiService.getEntityContractualArrangements(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchEntity = async (entityData: any) => {
  try {
    const response = await ApiService.postEntity(entityData);

    notification.success({
      message: response.status,
      description: 'Entity - successfuly saved',
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchBranches = async (data: any) => {
  try {
    const response = await ApiService.getBranches(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchContractualArrangementsLei = async (data: any) => {
  try {
    const response = await ApiService.getContractualArrangementsLei(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchContractualArrangementsICT = async (data: any) => {
  try {
    const response = await ApiService.getContractualArrangementsICT(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchContractualArrangementsICTUse = async (data: any) => {
  try {
    const response = await ApiService.getContractualArrangementsICTUse(data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchEntityType = async (entityTypeData: any) => {
  try {
    const response = await ApiService.postEntityType(entityTypeData);

    notification.success({
      message: response.status,
      description: 'Entity Type- successfuly saved',
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};

const fetchHierarchyGroup = async (hierarchyGroupData: any) => {
  try {
    const response = await ApiService.postHierarchyGroup(hierarchyGroupData);

    notification.success({
      message: response.status,
      description: 'Hierarchy Group- successfuly saved',
    });

    return response;
  } catch (error) {
    console.error(error);
  }
};


const setAllEntities = async () => {
  try {
    const response = await ApiService.getAllEntities();

    return response.data;
  } catch (error) {
    console.error(error);
  }
};


export default {
  setTheme,
  setSearchParams,
  setAppliedFilersResult,
  setTableFilter,
  setFilters,
  fetchEntityContractualArrangements,
  fetchEntity,
  fetchBranches,
  fetchContractualArrangementsLei,
  fetchContractualArrangementsICT,
  fetchContractualArrangementsICTUse,
  fetchEntityType,
  fetchHierarchyGroup,
  setAllEntities
};

