import styled from 'styled-components';

export const GridContainer = styled('div')`
  display: flex;
  column-gap: 170px;
  row-gap: 48px;
  flex-wrap: wrap;
  justify-content: center;

  > a {
    height: fit-content;
    width: 350px;
  }
`;

export const Container = styled('div')`
  padding: 0 50px;
`;
