import React, { useState } from 'react';
import { Popconfirm, Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import CustomTable from 'components/CustomTable/CustomTable';
import * as Styled from '../Entity.styled';
import { globalOp } from 'store/ducks/Global';

interface IEntityTypeDataType {
  key: number;
  id: number;
  name: string;
  description: string;
}

const EntityType: React.FC = () => {
  const [dataSource, setDataSource] = useState<IEntityTypeDataType[]>([
    {
      key: 0,
      id: 1,
      name: '',
      description: '',
    }
  ]);

  const [count, setCount] = useState(2);

  const handleAdd = () => {
    const newData: IEntityTypeDataType = {
      key: count,
      id: count,
      name: ``,
      description: ``,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleDelete = (key: React.Key) => {
    setDataSource(dataSource.filter((item) => item.key !== key));
  };

  const handleSave = (row: IEntityTypeDataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', width: '10%', editable: false },
    { title: 'Name', dataIndex: 'name', width: '30%', editable: true },
    { title: 'Description', dataIndex: 'description', editable: true },
    {
      title: 'operation',
      dataIndex: 'operation',
      width: '5%',
      render: (_: any, record: { key: React.Key }) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDelete(record.key)}
        >
          <Styled.RemoveRow>
            <DeleteOutlined />
          </Styled.RemoveRow>
        </Popconfirm>
      ),
    },
  ];

  const handleSubmit = async () => {
    const allFieldsFilled = dataSource.every((item) => {
      return (
        item.name &&
        item.description
      );
    });
  
    if (!allFieldsFilled) {
      notification.error({
        message: 'Validation Error',
        description: 'Please fill in all fields before submitting.',
        placement: 'topRight',
      });
      return;
    }
  
    try {
      const formattedDataSource = dataSource.map((item) => ({
        ...item
      }));
      const response = await globalOp.fetchEntityType(formattedDataSource);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };

  return (
    <>
      <CustomTable
        columns={columns}
        dataSource={dataSource as IEntityTypeDataType[]}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        handleSave={handleSave} />
      <Button type="primary" onClick={handleSubmit} style={{ marginTop: 16 }}>
        Submit
      </Button>
      </>
  );
};

export default EntityType;
