import styled from 'styled-components';
import { Layout, Card, Button, Drawer } from 'antd';
import { IToken, ThemeList } from 'store/ducks/globalTypes';
import { variablesList } from 'constants/variablesList';
import { IThemeSelectorProps } from 'components/ThemeSelector/ThemeSelector';

const { Header } = Layout;

export const StyledLayout = styled(Layout)<IToken>`
  min-height: 100vh;

  > .ant-layout {
    min-height: initial;

    .ant-layout-header {
      height: fit-content;
    }
  }

  aside {
    padding-right: ${(props) => `${props.token.paddingLG}px`};
    flex: initial !important;
    text-align: center;
    max-width: fit-content !important;
    min-width: fit-content !important;
    width: fit-content !important;
    position: relative !important;
    right: 0px;
  }

  h4 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    padding-right: 34px;
  }
`;

export const AntDrawer = styled(Drawer)`
  .ant-drawer-mask {
    background-color: red;
  }
`;

export const HorizontalLayout = styled('div')<IThemeSelectorProps>`
  padding: 0 24px 24px;
  border-bottom: 1px solid
    ${(props) => variablesList[props.theme as ThemeList].defaultTabBg};

  .ant-tabs-nav-list {
    width: -webkit-fill-available;
    margin-bottom: 1rem;

    .ant-tabs-tab {
      width: -webkit-fill-available;
      display: table;
    }
  }
`;

export const StyledHeader = styled(Header)<IToken>`
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding-inline: ${(props) => `${props.token.paddingLG}px`};
  line-height: ${(props) => `${props.token.lineHeightHeading2}px`};
`;

export const GridContainer = styled('div')`
  display: flex;
  column-gap: 170px;
  row-gap: 48px;
  flex-wrap: wrap;
  justify-content: center;

  > a {
    height: fit-content;
    width: 350px;
  }

  .ant-card {
    width: 350px;

    .ant-card-meta-title {
      white-space: pre-line !important;
    }

    .ant-card-body {
      cursor: pointer;
    }
  }
`;

export const Container = styled('div')`
  padding: 0 50px;
`;

export const ReportCard = styled(Card)`
  width: 350px;
  text-align: center;

  .ant-btn {
    margin-top: 9px;
    width: -webkit-fill-available;
    font-size: 16px;
    font-weight: 600;
  }

  .ant-card-body {
    cursor: auto !important;
  }
`;

export const StyledButton = styled(Button)`
  width: 110px;
  text-transform: uppercase;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
  margin-top: 20px;
`;

export const FilterContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 23px;
  gap: 8px;
`;

export const OpenCloseButton = styled(Button)`
  width: 30px;
  text-transform: uppercase;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: fit-content;
`;


export const ExcelDownload = styled.div`
    text-align: -webkit-center;
    margin-bottom: 3rem;

    button {
      width: auto;
    }
`