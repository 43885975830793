import React, { useState, useEffect } from 'react';
import { Button, Popconfirm, DatePicker, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { globalOp } from 'store/ducks/Global';
import CustomTable from 'components/CustomTable/CustomTable';
import dayjs from 'dayjs';
import {
  EditableColumnType,
  DataType,
} from 'components/CustomTable/CustomTable';

interface IEntityTitle {
  key: number;
  id: number;
  lei_entity_maintaining_register_information: string;
  name_of_entity: string;
  entity_type_id: number;
  lei_of_entity: string;
  hierarchy_in_group_id: number;
  parent_lei: string;
  lei_entity_making_use_ICT_service: string;
  lei_entity_signing_contractual_arrangement: string;
  nature_id: number;
  integration_date: string;
  deletion_date: string;
  total_assets: number;
  lei_financial_entity: string;
  description: string;
  competent_authority_id: number;
  country_id: number;
  isNew?: boolean;
  isEdited?: boolean;
}

type FixedType = 'left' | 'right' | undefined;

const Entity: React.FC = () => {
  const [dataSource, setDataSource] = useState<IEntityTitle[]>([
    {
      key: 0,
      id: 1,
      lei_entity_maintaining_register_information: '',
      name_of_entity: '',
      entity_type_id: 1,
      lei_of_entity: '',
      hierarchy_in_group_id: 1,
      parent_lei: '',
      lei_entity_making_use_ICT_service: '',
      lei_entity_signing_contractual_arrangement: '',
      nature_id: 1,
      integration_date: '',
      deletion_date: '',
      total_assets: 1,
      lei_financial_entity: '',
      description: '',
      competent_authority_id: 1,
      country_id: 1,
    },
  ]);

  const [count, setCount] = useState(1);

  useEffect(() => {
    const fetchEntities = async () => {
      try {
        const response = await globalOp.setAllEntities();
        const data = response.entities;
        const mappedData = data.map((item: any, index: number) => ({
          key: index,
          id: item.id,
          lei_entity_maintaining_register_information: item.lei_entity_maintaining_register_information || '',
          name_of_entity: item.name_of_entity || '',
          entity_type_id: item.entity_type_id || 0,
          lei_of_entity: item.lei_of_entity || '',
          hierarchy_in_group_id: item.hierarchy_in_group_id || 0,
          parent_lei: item.parent_lei || '',
          lei_entity_making_use_ICT_service: item.lei_entity_making_use_ICT_service || '',
          lei_entity_signing_contractual_arrangement: item.lei_entity_signing_contractual_arrangement || '',
          nature_id: item.nature_id || 0,
          integration_date: item.integration_date || '',
          deletion_date: item.deletion_date || '',
          total_assets: item.total_assets || 0,
          lei_financial_entity: item.lei_financial_entity || '',
          description: item.description || '',
          competent_authority_id: item.competent_authority_id || 0,
          country_id: item.country_id || 0,
          isNew: false,
          isEdited: false,
        }));
        setDataSource(mappedData);
        setCount(mappedData.length);
      } catch (error) {
        console.error('Error fetching entities:', error);
      }
    };

    fetchEntities();
  }, []);

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const handleAddRow = () => {
    const maxId = dataSource.reduce((max, item) => (item.id > max ? item.id : max), 0);
    const newId = maxId + 1;

    const newData: IEntityTitle = {
      key: newId,
      id: newId,
      lei_entity_maintaining_register_information: '',
      name_of_entity: '',
      entity_type_id: 1,
      lei_of_entity: '',
      hierarchy_in_group_id: 1,
      parent_lei: '',
      lei_entity_making_use_ICT_service: '',
      lei_entity_signing_contractual_arrangement: '',
      nature_id: 1,
      integration_date: '',
      deletion_date: '',
      total_assets: 1,
      lei_financial_entity: '',
      description: '',
      competent_authority_id: 1,
      country_id: 1,
      isNew: true, 
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: IEntityTitle) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    newData.splice(index, 1, {
      ...newData[index],
      ...row,
      total_assets: Number(row.total_assets),
      isEdited: !newData[index].isNew,
    });
    setDataSource(newData);
  };

  const handleDateChange = (key: number, field: string, dateString: string) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);

    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, { ...item, [field]: dateString, isEdited: !item.isNew });
      setDataSource(newData);
      console.log(dataSource, 'dataSource');
    }
  };
  const handleSubmit = async () => {
    const filteredData = dataSource.filter((item) => item.isNew || item.isEdited);
  
    if (filteredData.length === 0) {
      notification.warning({
        message: 'No Changes',
        description: 'There are no new or edited entities to submit.',
        placement: 'topRight',
      });
      return;
    }
  
    const allFieldsFilled = filteredData.every((item) => {
      return (
        item.lei_entity_maintaining_register_information &&
        item.name_of_entity &&
        item.entity_type_id &&
        item.lei_of_entity &&
        item.hierarchy_in_group_id &&
        item.parent_lei &&
        item.lei_entity_making_use_ICT_service &&
        item.lei_entity_signing_contractual_arrangement &&
        item.nature_id &&
        item.integration_date &&
        item.deletion_date &&
        item.total_assets &&
        item.lei_financial_entity &&
        item.description &&
        item.competent_authority_id &&
        item.country_id
      );
    });
  
    if (!allFieldsFilled) {
      notification.error({
        message: 'Validation Error',
        description: 'Please fill in all fields before submitting.',
        placement: 'topRight',
      });
      return;
    }
  
    try {
      const formattedDataSource = filteredData.map((item) => ({
        ...item,
        integration_date: item.integration_date ? item.integration_date : null,
        deletion_date: item.deletion_date ? item.deletion_date : null,
      }));
  
      console.log(formattedDataSource, 'formattedDataSource');
      const response = await globalOp.fetchEntity(formattedDataSource);
    } catch (error) {
      notification.error({
        message: 'Submission Error',
        description: 'There was an error sending the data. Please try again.',
        placement: 'topRight',
      });
    }
  };
  
  const defaultColumns: EditableColumnType[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      editable: false,
    },
    {
      title: 'LEI Entity Maintaining Register Information',
      dataIndex: 'lei_entity_maintaining_register_information',
      editable: true,
    },
    {
      title: 'Name of Entity',
      dataIndex: 'name_of_entity',
      editable: true,
    },
    {
      title: 'Entity Type ID',
      dataIndex: 'entity_type_id',
    },
    {
      title: 'LEI of Entity',
      dataIndex: 'lei_of_entity',
      editable: true,
    },
    {
      title: 'Hierarchy in Group ID',
      dataIndex: 'hierarchy_in_group_id',
    },
    {
      title: 'Parent LEI',
      dataIndex: 'parent_lei',
      editable: true,
    },
    {
      title: 'LEI Entity Making Use of ICT Service',
      dataIndex: 'lei_entity_making_use_ICT_service',
      editable: true,
    },
    {
      title: 'LEI Entity Signing Contractual Arrangement',
      dataIndex: 'lei_entity_signing_contractual_arrangement',
      editable: true,
    },
    {
      title: 'Nature ID',
      dataIndex: 'nature_id',
    },
    {
      title: 'Integration date',
      dataIndex: 'integration_date',
      editable: false,
      render: (text: string, record: DataType) => {
        return (
          <div style={{ position: 'relative', zIndex: 10 }}>
            <DatePicker
              value={text ? dayjs(text, 'YYYY-MM-DD') : null}
              format="YYYY-MM-DD"
              onClick={(e) => e.stopPropagation()}
              onChange={(date, dateString) => {
                const formattedDateString = Array.isArray(dateString)
                  ? dateString[0]
                  : dateString;
                handleDateChange(
                  record.key,
                  'integration_date',
                  formattedDateString,
                );
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Deletion date',
      dataIndex: 'deletion_date',
      editable: false,
      render: (text: string, record: DataType) => (
        <DatePicker
          value={text ? dayjs(text, 'YYYY-MM-DD') : null}
          format="YYYY-MM-DD"
          onClick={(e) => e.stopPropagation()}
          onChange={(date, dateString) => {
            console.log(record.deletion_date, 'record.deletion_date');
            const formattedDateString = Array.isArray(dateString)
              ? dateString[0]
              : dateString;
            handleDateChange(record.key, 'deletion_date', formattedDateString);
          }}
        />
      ),
    },
    {
      title: 'Total Assets',
      dataIndex: 'total_assets',
      editable: true,
    },
    {
      title: 'LEI Financial Entity',
      dataIndex: 'lei_financial_entity',
      editable: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
    },
    {
      title: 'Competent Authority ID',
      dataIndex: 'competent_authority_id',
    },
    {
      title: 'Country ID',
      dataIndex: 'country_id',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_: any, record: { key: React.Key }) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <Button danger icon={<DeleteOutlined />} />
          </Popconfirm>
        ) : null,
      fixed: 'right' as FixedType,
    },
  ];

  return (
    <div>
      <CustomTable
        columns={defaultColumns}
        dataSource={dataSource}
        handleAdd={handleAddRow}
        handleDelete={handleDelete}
        handleSave={handleSave}
      />
      <Button type="primary" onClick={handleSubmit} style={{ marginTop: 16 }}>
        Submit
      </Button>
    </div>
  );
};

export default Entity;
