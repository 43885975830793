import React from 'react';
import WorkingArea from 'components/WorkingAreas/WorkingAreas';
import InsourceLayout from 'components/Layout/DataEntryLayout/Layout';
import OutsourceLayout from 'components/Layout/ReportingLayout/Layout';
import StepsComponent from 'pages/DataEntrySteps/Steps';

const routes = [
  {
    id: 1,
    path: '/',
    private: false,
    component: () => <WorkingArea />,
  },
  {
    id: 2,
    path: '/data-entry',
    private: false,
    component: () => <InsourceLayout />,
  },
  {
    id: 3,
    path: '/reporting',
    private: false,
    component: () => <OutsourceLayout />,
  },
  {
    id: 4,
    path: '/data-entry/entity',
    private: false,
    component: () => <StepsComponent />,
  },
];

export default routes;
