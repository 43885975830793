import React, { useState } from 'react';
import { Popconfirm, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import EditableTable from 'components/CustomTable/CustomTable';
import * as Styled from '../Entity.styled';

interface CompetentAuthorityDataType {
  key: number;
  id: number;
  name: string;
  description: string;
}

const CompetentAuthority: React.FC = () => {
  const [dataSource, setDataSource] = useState<CompetentAuthorityDataType[]>([
    {
      key: 0,
      id: 0,
      name: 'Competent Authority 0',
      description: 'Competent Authority description 0',
    },
    {
      key: 1,
      id: 1,
      name: 'Competent Authority 1',
      description: 'Competent Authority description 1',
    },
  ]);

  const [count, setCount] = useState(2);

  const handleAdd = () => {
    const newData: CompetentAuthorityDataType = {
      key: count,
      id: count,
      name: ``,
      description: ``,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleDelete = (key: React.Key) => {
    setDataSource(dataSource.filter((item) => item.key !== key));
  };

  const handleSave = (row: CompetentAuthorityDataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', width: '5%' },
    { title: 'Name', dataIndex: 'name', width: '30%', editable: true },
    { title: 'Description', dataIndex: 'description', editable: true },
    {
      title: 'operation',
      dataIndex: 'operation',
      width: '5%',
      render: (_: any, record: { key: React.Key }) => (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDelete(record.key)}
        >
          <Styled.RemoveRow>
            <DeleteOutlined />
          </Styled.RemoveRow>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <EditableTable
      columns={columns}
      dataSource={dataSource as CompetentAuthorityDataType[]}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
      handleSave={handleSave}
    />
    <Button type="primary" style={{ marginTop: 16 }}>
      Submit
    </Button>
    </>
  );
};

export default CompetentAuthority;
