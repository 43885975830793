import React from 'react';
import { RollbackOutlined } from '@ant-design/icons';
import * as Styled from './GoBackButton.styled';

const GoBackButton: React.FC = () => {
  return (
    <Styled.StyledLink to="/">
      <Styled.StyledGoBackButton type="primary">
        Working Areas
        <RollbackOutlined />
      </Styled.StyledGoBackButton>
    </Styled.StyledLink>
  );
};

export default GoBackButton;
