import styled from 'styled-components';
import { IToken } from 'store/ducks/globalTypes';
import ReactBrightLogo from 'assets/img/logo_version2.webp';

export const StyledNav = styled('div')<IToken>`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => `0px ${props.token.paddingLG}px`};
`;

export const StyledLogo = styled.img`
  width: 80px;
  margin: 10px 0;
`;

export const logoSrc = ReactBrightLogo;

export const DropdownContainer = styled('div')`
  margin-top: 20px;
  display: flex;
  gap: 15px;
`;
