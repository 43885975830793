import styled from 'styled-components';

export const StepsComponent = styled('div')`
  padding: 20px 50px;

  .ant-steps-item {
    flex: auto;

    .ant-steps-item-title {
      font-size: 15px;
    }
  }
`;
