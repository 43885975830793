import styled from 'styled-components';

export const TypeComponent = styled('div')`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 4px 11px;
    cursor: pointer;
    color: black !important;
    border: none;
    border-radius: 2px;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  .ant-table-cell a {
    &:hover {
      color: black;
    }
  }
  .ant-picker-dropdown {
    z-index: 1050;
  }
  .ant-table-cell-row-hover .ant-picker {
    pointer-events: auto;
  }
  .ant-picker-input {
    color: black !important;
  }
`;

export const RemoveRow = styled.a`
  color: black;

  span svg {
    font-size: 20px;
  }
`;
